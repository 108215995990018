import { defineStore } from "pinia";
import { FormData, Assesor } from "@/core/types/gws-ap2tki/lsp/FormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";


export const useAp2tkiLspFormStore = defineStore({
  id: "ap2tkiLspFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      statePhoto: {
        front: '' as string,
        back: '' as string,
        facility: '' as string,
      },
      stateAssesor: [] as Assesor[],
      stateUpdateId: '' as string,
      stateSelectedProvince: '' as string,
    };
  },
  getters: {
    loading: (state) => {
      return state.stateIsLoading;
    },
    formData: (state) => {
      return state.stateFormData;
    },
    assessor: (state) => {
      return state.stateAssesor;
    },
    photo: (state) => {
      return state.statePhoto;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          vocational: this.formData.vocational,
          destination_country_id: this.formData.destination_country_id,
          cv_file: this.formData.cv_file,
          address: this.formData.address,
          licensing_number: this.formData.licensing_number,
          email: this.formData.email,
          password: this.formData.password,
          licensing_file: this.formData.licensing_file,
          sk_number: this.formData.sk_number,
          lsp_start_date: this.formData.lsp_start_date,
          lsp_end_date: this.formData.lsp_end_date,
          city_id: this.formData.city_id,
          province_id: this.formData.province_id,
          pic_name: this.formData.pic_name,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          head_photo_file: this.formData.head_photo_file,
          head_name: this.formData.head_name,
          head_email: this.formData.head_email,
          head_cv_file: this.formData.head_cv_file,
          head_nik: this.formData.head_nik,
          head_phone: this.formData.head_phone,
          lsp_photo_file: this.statePhoto,
          assessor: this.stateAssesor,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/lsp`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          vocational: this.formData.vocational,
          destination_country_id: this.formData.destination_country_id,
          cv_file: this.formData.cv_file,
          address: this.formData.address,
          licensing_number: this.formData.licensing_number,
          email: this.formData.email,
          password: this.formData.password,
          licensing_file: this.formData.licensing_file,
          sk_number: this.formData.sk_number,
          lsp_start_date: this.formData.lsp_start_date,
          lsp_end_date: this.formData.lsp_end_date,
          city_id: this.formData.city_id,
          province_id: this.formData.province_id,
          pic_name: this.formData.pic_name,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_title: this.formData.pic_title,
          pic_nip: this.formData.pic_nip,
          pic_signature_file: this.formData.pic_signature_file,
          head_photo_file: this.formData.head_photo_file,
          head_name: this.formData.head_name,
          head_email: this.formData.head_email,
          head_cv_file: this.formData.head_cv_file,
          head_nik: this.formData.head_nik,
          head_phone: this.formData.head_phone,
          lsp_photo_file: this.statePhoto,
          assessor: this.stateAssesor,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.patch(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/lsp/${this.stateUpdateId}`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/lsp/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async switchStatus(status: boolean) {
      this.stateIsLoading = true;
      try {
        const payload = {
          is_active: status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/lsp/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
          this.stateIsLoading = false;
        } else {
          this.stateError = true;
          this.stateIsLoading = false;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
      this.stateError = this.stateErrors.length > 0;
    },
    setFormData(data: FormData) {
      this.stateFormData = data;
    },
    resetAdditional() {
      this.statePhoto = {
        front: '' as string,
        back: '' as string,
        facility: '' as string,
      }
      this.stateAssesor = [] as Assesor[];
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
